.rate-request-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}


table {
    border: 1px solid red;
    border-collapse: collapse;
}

th {
    border: 1px solid red;
    padding: 2px;
    width: 100px;
    background: gold;
}

tr {
    border: 2px solid #090;
}

td {
    border: 2px solid #090;
    padding: 5px 0;
}

select {
    width: 100px;
    border-style: none;
}

label {
    width: 100px;
    border-style: none;
}


h3 {
    color: red;
    text-decoration: underline;
}

table tfoot tr td {
    font-weight: bold;
    color: red;
}